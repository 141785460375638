/*jshint esversion: 6 */

import $ from "jquery";

$(document).on('click', '[rel="toggle-class"]', function() {
  let $this = $(this);
  let $target = $this.data("target");
  let className = $this.data("toggle-class");

  $this.trigger("toggleClass.willToggle");
  $($target).toggleClass(className);
  $this.trigger("toggleClass.didToggle");

  return false;
});

//tweet this
$(".tweet-this-sidebar__button").click(function(e) {
  e.preventDefault();
  let text = $('.tweet-this-sidebar__content').text();
  let url = "https://twitter.com/intent/tweet?text=" + encodeURIComponent(text + " " + window.location);
  window.open(url, "_blank");
});