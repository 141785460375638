import "ts-polyfill"

import $ from "jquery"
import "../modules/newsroom/assets/styles/floatingLabelTextInput"

window.$ = $
window.jQuery = $

import "bootstrap"
import "./toggleClass"
import { Basket, LocalBasketStore, RemoteBasketStore } from "../modules/newsroom/app/assets/javascripts/newBasket"
import "../modules/newsroom/app/assets/javascripts/nr2"
import { getCookie, createCookie } from "../modules/newsroom/app/assets/javascripts/util/cookies"
import moment from "moment"
import "daterangepicker"
import Masonry from "masonry-layout"
import jQueryBridget from 'jquery-bridget'
import toastr from "toastr"
import './jquery.slimmenu.js'

declare global {
  interface Window {
    // So we can call window.initMasonryGrids from anywhere.
    initMasonryGrids()
    setupBasket()
    setupGuestBasket()
    acceptLicense()
    activateRelatedMediaTab()

    // Necessary for many legacy tools so that window.$ and window.jQuery remain accessible
    $: JQueryStatic
    jQuery: JQueryStatic
    basket: Basket
    localBasketDataStore: LocalBasketStore
  }

  interface SlimMenuSettings {
    // JQuery element that toggles the menu visibility
    menuToggleButton: JQuery<any>
    mobileMenuDisplayAtMinWidth?: number
  }

  interface JQuery {
    slimmenu(options?: SlimMenuSettings)
  }
}

toastr.options.closeButton = true

// make Masonry a jQuery plugin
jQueryBridget('masonry', Masonry, $)

// ====================================================================================================================
// Utility
// ====================================================================================================================
function updateQueryStringParameter(uri: string, key: string, value: string): string {
  const re = new RegExp("([?&])" + key + "=.*?(&|$)", "i")
  const separator = uri.indexOf('?') !== -1 ? "&" : "?"
  if (uri.match(re)) {
    return uri.replace(re, '$1' + key + "=" + value + '$2')
  } else {
    return uri + separator + key + "=" + value
  }
}

// https://stackoverflow.com/questions/1353684/detecting-an-invalid-date-date-instance-in-javascript
function isValidDate(d: Date) {
  // @ts-ignore
  return isFinite(d)
}

declare global {
  interface Array<T> {
    unique(): Array<T>
  }
}

Array.prototype.unique = function() {
  return this.filter((item, index) => this.indexOf(item) === index)
}

function getParameterByName(name){
  const regexS = "[\\?&]"+name+"=([^&#]*)",
    regex = new RegExp( regexS ),
    results = regex.exec( window.location.search )
  if(results == null){
    return ""
  } else{
    return decodeURIComponent(results[1].replace(/\+/g, " "))
  }
}

class QuerystringArguments extends Map<string, string> {

  private parser = /([^&=]+)=?([^&]*)/g
  private decode(s: string): string { return decodeURIComponent(s.replace(/\+/g, " ")) }

  // Builds a map representing the current querystring arguments
  constructor() {
    super()

    let match,
        query = window.location.search.substring(1)

    while (match = this.parser.exec(query)) {
      this.set(this.decode(match[1]), this.decode(match[2]))
    }
  }

}

function removeParameter(url: string, parameter: string): string {
  //prefer to use l.search if you have a location/link object
  const urlparts = url.split('?')

  if (urlparts.length >= 2) {
    const prefix = encodeURIComponent(parameter) + '='
    const pars = urlparts[1].split(/[&;]/g)

    //reverse iteration as may be destructive
    for (let i = pars.length; i-- > 0;) {
      if (pars[i].startsWith(prefix)) {
        pars.splice(i, 1)
      }
    }

    url = urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : "")
    return url
  } else {
    return url
  }
}

function showLicenseAgreementModal(downloadUrl: string) {
  const $licenseModal = $("#licenseModal")

  $.get("/licenses/default")
    .done(function(modalBody) {
      $("#licenseModal .modal-dialog .modal-content").html(modalBody)
      $licenseModal.remove().appendTo("body")
      $licenseModal.data("downloadUrl", downloadUrl)
      $licenseModal.modal('show')
    })
}

function showLicenseAgreementModalIfNecessary() {
  // License Agreement Accept/Decline Modal
  if( getParameterByName('la') === "1" ) {
    const downloadUrl = getParameterByName("downloadUrl")
    if(downloadUrl.length > 0) {
      showLicenseAgreementModal(downloadUrl)
    }
  }
}

// ====================================================================================================================
// Date Range Dropdown in contentTypeTabBar
// ====================================================================================================================
$(() => {
  const $startDateSinglePicker = $('input[name="startDateSingle"]')
  const $endDateSinglePicker = $('input[name="endDateSingle"]')

  let endDateSelected = false
  let startDateSelected = false

  const validateForm = function() {
    if(startDateSelected && endDateSelected) {
      $("button.apply-date-filter").removeAttr("disabled")
    } else {
      $("button.apply-date-filter").attr("disabled", "disabled")
    }
  }

  if($startDateSinglePicker.length && $endDateSinglePicker.length) {
    $startDateSinglePicker.daterangepicker({
      parentEl: "#daterange-desktop__dropdown",
      autoUpdateInput: false,
      singleDatePicker: true,
      maxYear: parseInt(moment().format('YYYY'), 10),
      locale: {
        format: 'MM/DD/YYYY'
      }
    }, function(start, end, label) {
      $startDateSinglePicker.data('daterangepicker').autoUpdateInput = true
      startDateSelected = true

      $endDateSinglePicker.data('daterangepicker').minDate = start
      $startDateSinglePicker.val(start.format('MM/DD/YYYY'))

      validateForm()
    })

    $endDateSinglePicker.daterangepicker({
      parentEl: "#daterange-desktop__dropdown",
      autoUpdateInput: false,
      singleDatePicker: true,
      maxYear: parseInt(moment().format('YYYY'), 10),
      locale: {
        format: 'MM/DD/YYYY'
      }
    }, function(start, end, label) {
      $endDateSinglePicker.data('daterangepicker').autoUpdateInput = true
      endDateSelected = true

      $startDateSinglePicker.data('daterangepicker').maxDate = start
      $endDateSinglePicker.val(start.format('MM/DD/YYYY'))

      validateForm()
    })
  }

  $("button.refine-search-quick-filter").on("click", function() {
    $startDateSinglePicker.data('daterangepicker').autoUpdateInput = true
    $endDateSinglePicker.data('daterangepicker').autoUpdateInput = true

    switch($(this).data("filter-name")) {
      case "7 days":
        $startDateSinglePicker.data('daterangepicker').setStartDate(moment().subtract(6, 'days'))
        break

      case "30 days":
        $startDateSinglePicker.data('daterangepicker').setStartDate(moment().subtract(29, 'days'))
        break

      case "6 months":
        $startDateSinglePicker.data('daterangepicker').setStartDate(moment().subtract(6, 'month'))
        break

      case "1 year":
        $startDateSinglePicker.data('daterangepicker').setStartDate(moment().subtract(1, 'year'))
        break

      case "All Time":
        $("button.clear-date-filter").trigger("click")
        return false
    }

    $endDateSinglePicker.data('daterangepicker').setStartDate(moment())

    startDateSelected = true
    endDateSelected = true

    validateForm()

    $("button.apply-date-filter").trigger("click")
  })

  $("button.apply-date-filter").on("click", function() {
    const startDate = $startDateSinglePicker.data('daterangepicker').startDate
    const endDate = $endDateSinglePicker.data('daterangepicker').startDate

    $("#filterForm input#startDay").val(startDate.date())
    $("#filterForm input#startMonth").val(startDate.month() + 1)
    $("#filterForm input#startYear").val(startDate.year())

    $("#filterForm input#endDay").val(endDate.date())
    $("#filterForm input#endMonth").val(endDate.month() + 1)
    $("#filterForm input#endYear").val(endDate.year())

    paginatedSearch()
  })

  $("button.clear-date-filter").on("click", function() {
    startDateSelected = false
    endDateSelected = false

    $startDateSinglePicker.data('daterangepicker').maxDate = null
    $endDateSinglePicker.data('daterangepicker').maxDate = null

    $startDateSinglePicker.val("")
    $endDateSinglePicker.val("")
    $startDateSinglePicker.data('daterangepicker').autoUpdateInput = false
    $endDateSinglePicker.data('daterangepicker').autoUpdateInput = true

    $("#filterForm input#startDay").val("")
    $("#filterForm input#startMonth").val("")
    $("#filterForm input#startYear").val("")

    $("#filterForm input#endDay").val("")
    $("#filterForm input#endMonth").val("")
    $("#filterForm input#endYear").val("")

    validateForm()
    paginatedSearch()
  })

  $("#startDateSingleIcon").on("click",() => $startDateSinglePicker.trigger("focus"))
  $("#endDateSingleIcon").on("click",() => $endDateSinglePicker.trigger("focus"))

  $(document).on("click", (e) => {
    const $target = $(e.target)

    if ($target.parents(".dropdown").length ||
        $target.parents(".calendar-table").length ||
        $target.parents(".table-condensed").length) { return }
    $(".dropdown").removeClass("open")
  })

  const initialStartDate = new Date([$("#filterForm input#startYear").val(), $("#filterForm input#startMonth").val(), $("#filterForm input#startDay").val()].join("/"))
  const initialEndDate = new Date([$("#filterForm input#endYear").val(), $("#filterForm input#endMonth").val(), $("#filterForm input#endDay").val()].join("/"))

  if(isValidDate(initialStartDate) && isValidDate(initialEndDate)) {
    $startDateSinglePicker.data('daterangepicker').autoUpdateInput = true
    $endDateSinglePicker.data('daterangepicker').autoUpdateInput = true

    startDateSelected = true
    endDateSelected = true

    $startDateSinglePicker.data('daterangepicker').setStartDate(initialStartDate)
    $endDateSinglePicker.data('daterangepicker').setStartDate(initialEndDate)
  }

  validateForm()
})


// ====================================================================================================================
// site/basket.js
// ====================================================================================================================
$(document).on("basket.change", function(e, count) {
  let message = "{0}"

  if (count === 0) {
    $(".clientBasketItemCount")
      .removeClass("clientBasketItemCount--full")
      .html("")
  } else if (count === 1) {
    message = "1"

    $(".clientBasketItemCount")
      .addClass("clientBasketItemCount--full")
      .html(message)
  } else {
    message = "{0}"

    $(".clientBasketItemCount")
      .addClass("clientBasketItemCount--full")
      .html(message.replace("{0}", count))
  }
})

window.localBasketDataStore = new LocalBasketStore()

if ($("body.authenticated").length > 0) {
  window.basket = new Basket(new RemoteBasketStore(window.localBasketDataStore))
} else {
  window.basket = new Basket(window.localBasketDataStore)
}


// ====================================================================================================================
// default/navHeader
// ====================================================================================================================

$(function() {

  $("#searchModal").on("shown.bs.modal", function() {
    $("#searchInput").trigger("focus")
  })

  let oldAction: string | null = null

  $("[data-toggle=modal][data-search-form-action]").on("click",function(e) {
    const searchAction = e.target.getAttribute("data-search-form-action")
    oldAction = $("#searchModal form").attr("action")!

    $("#searchModal form").attr("action", searchAction)
  })

  $("#searchModal").on("hidden.bs.modal", function() {
    if(oldAction != null) {
      $("#searchModal form").attr("action", oldAction)
      oldAction = null
    }
  })

})



// ====================================================================================================================
// Photo and Video Modal Dialogs
// ====================================================================================================================

let channelsConstraintQuerystring = ""

if (getParameterByName("channelsConstraint").length > 0) {
  channelsConstraintQuerystring = `?channelsConstraint=${getParameterByName("channelsConstraint")}`
}

$(document).on("hidden.bs.modal", ".media-modal", () => {
  const newUri = removeParameter(document.location.href, "modal")
  history.replaceState(null, document.title, newUri)
})

function initVideoPlayer($videoElem: JQuery) {
  const videoUri = $videoElem.data("uri")
  const keyframeUri = $videoElem.data("keyframe-uri")

  if($videoElem.get()[0] !== undefined) {
    jwplayer($videoElem.get()[0]).setup({
      file: videoUri,
      width: "100%",
      aspectratio: "16:9",
      autostart: false,
      image: keyframeUri
    })
  }
}

enum MediaModalAction {
  ShowMedia,
  ShowMediaAndShare,
  Preload
}

function modalNavigate(fromId: string, toId: string, ids: string[]) {
  return function(e) {
    e.preventDefault()
    if (fromId.indexOf("video-") === 0) {
      const videoElementId = "video-player-" + fromId;
      if(document.getElementById(videoElementId)) {
        jwplayer(videoElementId).stop()
      }
    }
    makeModal(toId, undefined, MediaModalAction.ShowMedia, ids)
    return false
  }
}

function makeModal(contentId: string, modalXHRUrl: string | undefined, action: MediaModalAction, ids: string[]): Promise<string> {
  console.log("makeModal", {contentId: contentId, target: modalXHRUrl, action: action})

  return new Promise((resolve, reject) => {
    const $modalContent = $("div.modal-content[data-basket-item-key=" + contentId + "]")

    if ($modalContent.length > 0) {
      // We've found an existing .modal-content that matches the contentId
      if(action == MediaModalAction.ShowMedia) {
        console.log("Showing .modal-content for", contentId)

        const newUri = updateQueryStringParameter(document.location.href, "modal", contentId)
        history.replaceState(null, document.title, newUri)

        $("div.media-modal .modal-content").hide()
        $("div.media-modal").modal("show")
        $modalContent.show()
      } else {
        console.log("Nothing to do for", contentId, "because we've already loaded it.")
      }

      resolve(contentId)
    } else {
      console.log(".modal-content not found for", contentId, "loading via XHR")
      const target: string = modalXHRUrl || $("div[data-basket-item-key=" + contentId + "]").find("a[data-toggle$=-modal]").attr("href")!
      const requestUrl = target + (target.match(/\?/) === null ? "?" : "&") + "xhr=1"

      $.ajax({
        url: requestUrl,
        dataType: "html",
        success: function(data) {
          const $data: JQuery = $(data)
          const $basketDataElem = $data.find("[data-basket-item-key]")
          const contentType: string = $basketDataElem.data("basket-item-type").toLowerCase()
          const first: boolean = $(".media-modal").length === 0
          const index: number = ids.indexOf(contentId)
          const nextId = ids[index + 1]
          const prevId = ids[index - 1]

          let $modalContent: JQuery

          if (first) {
            // For the first modal, append the entire XHR response to the body.
            $data.enableBasketControls()
            $("body").append($data)
            $(".media-modal")
              .modal("show")
              .on("hidden.bs.modal", function() {
                $(".media-modal").remove()
              })

            $modalContent = $(".media-modal .modal-content")
          } else {
            // For subsequent modals, grab just the div.modal-content that contains the photo preview
            // and append it to the one currently being shown.
            $modalContent = $data.find("div.modal-content[data-basket-item-key]")
            $modalContent.enableBasketControls()
            $(".media-modal .modal-dialog").append($modalContent.hide())
            // Also grab the share modal and append it to the body
            $("body").append($data.find("div.share-modal"))

            if(action == MediaModalAction.ShowMedia) {
              console.log("Hiding existing modals and showing " + contentId, $modalContent)
              // Hide the currently visible .modal-content
              $(".media-modal .modal-content").hide()
              // Show the new one that was just added to the DOM
              $modalContent.show()
              $(".media-modal").modal("show")
            }
          }

          if (contentType === "video") {
            initVideoPlayer($(data).find("div#video-player-" + contentId))
          }

          if(nextId !== undefined) {
            $modalContent
              .on("click", "[data-action=modal-next]", modalNavigate(contentId, nextId, ids))
          } else {
            $modalContent
              .find("[data-action=modal-next]")
              .attr("disabled", "disabled")
          }

          if(prevId !== undefined) {
            $modalContent
              .on("click", "[data-action=modal-prev]", modalNavigate(contentId, prevId, ids))
          } else {
            $modalContent
              .find("[data-action=modal-prev]")
              .attr("disabled", "disabled")
          }

          // TODO: re-implement Share Dialog logic
          // if(action == MediaModalAction.ShowMediaAndShare) {
          //   $("body").find("div#share-modal-" + contentId).modal('show').on('hidden.bs.modal', function() {
          //     $(".media-modal, div#share-modal-" + contentId + ", div.modal-backdrop").remove()
          //   })
          // }

          if (action == MediaModalAction.ShowMedia) {
            const newUri = updateQueryStringParameter(document.location.href, "modal", contentId)
            history.replaceState(null, document.title, newUri)

            if(nextId !== undefined) {
              console.log("During show for", contentId, "prefetching next:", nextId)
              makeModal(nextId, undefined, MediaModalAction.Preload, ids)
            }

            if(prevId !== undefined) {
              console.log("During show for", contentId, "prefetching prev:", prevId)
              makeModal(prevId, undefined, MediaModalAction.Preload, ids)
            }
          }

          resolve(contentId)
        }
      })
    }
  })
}

const paginatedSets: Map<string, string[]> = $("[data-paginated-set-id]").toArray().reduce((p, c, i, a) => {
  p.set(c.getAttribute("data-paginated-set-id"),
        $(c).find("[data-basket-item-type]")
            .toArray()
            .map((e) => e.getAttribute("data-basket-item-key"))
            .unique())

  return p
}, new Map())

function paginatedSetIdForContentId(contentId: string): string | undefined {
  const result = Array
    .from(paginatedSets)
    .find(([k, v]) => v.indexOf(contentId) > -1)

  if(result !== undefined) {
    return result[0]
  } else {
    return undefined
  }
}

function paginatedSetForContentId(contentId: string): string[] {
  const paginatedSetId = paginatedSetIdForContentId(contentId)

  if(paginatedSetId !== undefined) {
    return paginatedSets.get(paginatedSetId)!
  } else {
    return [contentId]
  }
}

const querystringModal = document.location.href.match(/modal=((photo|video)-[^&\b]*)?/)

if(querystringModal !== null) {
  let contentId = querystringModal[1]
  let idSet = paginatedSetForContentId(contentId)

  makeModal(contentId,"/" + querystringModal[2] + "s/" + querystringModal[1], MediaModalAction.ShowMedia, idSet)
    .then((contentId) => {
      showLicenseAgreementModalIfNecessary()
    })
} else {
  showLicenseAgreementModalIfNecessary()
}

// Opens the Photo or Video Preview Modal from List Items
$(document).on('click', '[data-toggle=photo-modal], [data-toggle=video-modal]', function(e){
  e.preventDefault()

  const $this = $(this)
  const target = $this.data("target")
  const paginatedSetId: string = $this.parents("[data-paginated-set-id]").data("paginated-set-id")

  console.log(`opening modal for ${target} w/ set from ${paginatedSetId}`)

  const idSet = paginatedSets.get(paginatedSetId) || $this.closest("[data-basket-item-key]").toArray().map((e, i) => e.getAttribute("data-basket-item-key"))
  const contentId = $this.closest("[data-basket-item-key]").data("basket-item-key")

  makeModal(contentId, target, MediaModalAction.ShowMedia, idSet)

  return false
})


// Handle Share Form Submission
$(document).on('submit', 'form[data-intent=share]', function(e) {
  e.preventDefault()
  const form = $(this)
  const results = form.find('div#share-result')

  $.post(form.attr('action')!, form.serialize())
    .done(function(message) {
      toastr.success(message)
    })
    .fail(function(data) {
      toastr.error("Your message did not send. Please make sure all form fields are filled out correctly, then try again.")
    })
})


// ====================================================================================================================
// Admin Edit Popovers
// ====================================================================================================================
$(document).popover({
  selector: '[data-edit-url]',
  placement: function() { return $(this.element).data('edit-popover-position') },
  html: true,
  trigger: 'hover',
  delay: { "show": 100, "hide": 800 },
  content: function() {
    const editLabel = $(this).data('edit-label') || "Edit"
    return '<a class="edit-popover-link" target="_blank" href="' + $(this).data('edit-url') + '">' + editLabel + '</a>'
  }
})


// ====================================================================================================================
// site-paginated-search.js
// ====================================================================================================================
function pageState() {
  let selectedTabId: string | undefined = $("li.content-type.active a").attr("href")
  if (selectedTabId !== undefined && selectedTabId.startsWith("#")) {
    selectedTabId = selectedTabId.slice(1)
  } else {
    selectedTabId = undefined
  }

  return {
    query: $("#searchInput").val() || '',
    year: $("#filterForm #year").val() || '',
    month: $("#filterForm #month").val() || '',
    startYear: $("#filterForm input#startYear").val() || '',
    startMonth: $("#filterForm input#startMonth").val() || '',
    startDay: $("#filterForm input#startDay").val() || '',
    endYear: $("#filterForm input#endYear").val() || '',
    endMonth: $("#filterForm input#endMonth").val() || '',
    endDay: $("#filterForm input#endDay").val() || '',
    sortOrder: $("#filterForm input#sortOrder").val() || '',
    selectedContentType: $("#filterForm input#selectedContentType").val() || '',
    selectedTabId: selectedTabId
  }
}

function queryString(state): string {
  let q = "";

  ['query',
   'year',
   'month',
   'startYear',
   'startMonth',
   'startDay',
   'endYear',
   'endMonth',
   'endDay',
   'sortOrder',
   'selectedContentType',
   'selectedTabId'
  ].forEach((name: string) => {
    if (state[name]) q += '&' + name + '=' + state[name]
  })

  return "?" + (q.length > 0 ? q.substring(1) : "")
}

function paginatedSearch(mergeState?) {
  window.location.href = queryString(Object.assign(pageState(), mergeState))
}

function updateSearchControls() {
  const urlParams = new QuerystringArguments()
  $("#searchInput").val(urlParams.get('query') || '')
  $("#filterForm #year").val(urlParams.get('year') || '')
  $("#filterForm #month").val(urlParams.get('month') || '')
  $("#filterForm input#startYear").val(urlParams.get('startYear') || '')
  $("#filterForm input#startMonth").val(urlParams.get('startMonth') || '')
  $("#filterForm input#startDay").val(urlParams.get('startDay') || '')
  $("#filterForm input#endYear").val(urlParams.get('endYear') || '')
  $("#filterForm input#endMonth").val(urlParams.get('endMonth') || '')
  $("#filterForm input#endDay").val(urlParams.get('endDay') || '')

  const startDateFromQuery = (urlParams.get('startMonth') || '') + "/" + (urlParams.get('startDay') || '') + "/" + (urlParams.get('startYear')  || '').substring(2, 4)
  const endDateFromQuery = (urlParams.get('endMonth') || '') + "/" + (urlParams.get('endDay') || '') + "/" + (urlParams.get('endYear') || '').substring(2, 4)

  if(startDateFromQuery != "//" && endDateFromQuery != "//") {
    let startDate = moment(startDateFromQuery).format("MM/DD/YYYY")
    let endDate = moment(endDateFromQuery).format("MM/DD/YYYY")

    $("#filterForm #daterange-desktop span").text(startDate + " - " + endDate)

    let startDateMobile = moment(startDateFromQuery).format("DD MMMM YYYY")
    let endDateMobile = moment(endDateFromQuery).format("DD MMMM YYYY")

    $("#daterange-mobile span").text(startDateMobile + " - " + endDateMobile)
  }

  $("#filterForm #sortOrder").val(urlParams.get('sortOrder') || '')
  $("#filterForm #selectedContentType").val(urlParams.get('selectedContentType') || '')
}

$(function() {
  updateSearchControls()

  $("ul#content-types li").on("click", function(e) {
    const newContentType = $(this).data("content-type")
    $("#filterForm input#selectedContentType").val(newContentType)

    // Add the selected tab to the href for each filtering option
    $("#filterForm ul#sort-options a").each(function(index) {
      $(this).attr("href", removeParameter($(this).attr("href")!, "selectedContentType") + "&selectedContentType=" + newContentType)
    })
  })

  let channelScopedSearch = false

  $("div.channel-scoped-search").on("click", function(e) {
    channelScopedSearch = true
    const channelName = $("div.content").data("channel-name")
    const placeholder = $(this).data("placeholder")
    $("div.search-modal-dialog input").attr("placeholder", placeholder + " " + channelName + "...")

    $("div.search-modal-dialog form").submit(function(e) {
      if(channelScopedSearch) {
        e.preventDefault()
        window.location.href = updateQueryStringParameter(window.location.pathname, "query", $("div.search-modal-dialog input").val())
      }
    })
  })

  $("div#navbar a.searchbox-icon, button.search-entire-site").on("click", function(e) {
    channelScopedSearch = false
    const placeholder = $(this).data("placeholder")
    $("div.search-modal-dialog input").attr("placeholder", placeholder)
  })
})


// When all of the img tags have finished loading, call the callback function
function tinyImagesLoaded(elements: JQuery<any>, callback: Function) {
  let imgTags = elements.find("img"),
      imagesRemaining = imgTags.length,
      imgCallbackFn = (e) => {
        imagesRemaining -= 1
        if(imagesRemaining === 0) {
          callback()
        }
      };

  if(imagesRemaining === 0) {
    callback()
  } else {
    imgTags.each((i, imgElement) => {
      if(imgElement.complete) {
        imagesRemaining -= 1
        if(imagesRemaining === 0) {
          callback()
        }
      } else {
        imgElement.addEventListener('load', imgCallbackFn, {once: true, passive: true})
        imgElement.addEventListener('error', imgCallbackFn, {once: true, passive: true})
      }
    })
  }
}


// Handle "Load More" clicks
$(document).on("click", "[data-paginated-set-id] a#next", function(e) {
  e.preventDefault()
  const $nextButton = $(this)
  const nextPage = $nextButton.attr("href")!

  $nextButton.attr("disabled", "true")
  $nextButton.text("Loading...")

  $.get(nextPage, function(data) {
    const $newItems = $(data)
    const $container = $nextButton.closest("[data-paginated-set-id]")
    const paginatedSetId = $container.data("paginated-set-id")

    $nextButton.closest(".footer-container").remove()

    if($container.hasClass("grid")) {
      $newItems.css('visibility', 'hidden')
      $container.append($newItems)

      // Append all of the newly loaded elements to the in-memory set of IDs used to manage the modal prev/next buttons
      const masonryElementIds = $newItems.filter((i, e) => e.classList !== undefined && e.classList.contains("grid__item") )
                                         .map((i, e) => e.getAttribute("data-basket-item-key")).toArray()

      paginatedSets.set(paginatedSetId,
                        paginatedSets.get(paginatedSetId)!.concat(masonryElementIds))

      tinyImagesLoaded($newItems, () => {
        $newItems.css('visibility', 'visible')
        $container.masonry("appended", $newItems)
      })
    } else {
      $container.append($newItems)
    }
  })
})


// ====================================================================================================================
// Masonry Layout
// ====================================================================================================================
window.initMasonryGrids = function() {
  $('.grid').each(function (i, obj) {
    const $grid = $(obj)

    $grid.masonry({
      itemSelector: 'none', // select none at first
      columnWidth: '.grid__col-sizer',
      gutter: '.grid__gutter-sizer',
      horizontalOrder: true,
      percentPosition: true,
      stagger: 30,
      // nicer reveal transition
      visibleStyle: {transform: 'translateY(0)', opacity: 1},
      hiddenStyle: {transform: 'translateY(100px)', opacity: 0}
    })

    // initial items reveal
    tinyImagesLoaded($grid, () => {
      $grid.removeClass('are-images-unloaded')
      $grid.masonry('option', {itemSelector: '.grid__item', transitionDuration: 0})
      const $items = $grid.find('.grid__item')
      $grid.masonry('appended', $items)
    })
  })

  // recalculate masonry layout on tab switch
  $('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
    let targetHref = this.getAttribute("href")!

    if(targetHref.indexOf("#") === 0) {
      $(targetHref)
        .find(".grid")
        .masonry()
    }
  })
}


// ====================================================================================================================
// Basket Share
// ====================================================================================================================
$(document).on("click", "#share-basket-submit", function(e){
  e.preventDefault()
  const $form = $(this).closest("form")
  const $button = $("#share-basket-submit")
  const $wait = $("#share-basket-submit-wait")

  $wait.removeClass("hidden")
  $button.attr("disabled", "disabled")

  $("#share-basket-modal").modal("hide")

  $.ajax({
    url: $form.attr("action"),
    data: $form.serialize(),
    method: "POST",
    dataType: "json",
    error: function(xhr) {
      $wait.addClass("hidden")
      $button.removeAttr("disabled")

      const result = xhr.responseJSON
      $("#share-basket-modal").find(".error").each(function(_, elem) {
        if ($(elem).is("span")) $(elem).remove()
        else $(elem).removeClass("error")
      })

      if (result.status === "KO") {
        $.each(result.message, function(field, message) {
          if (field === "") {
            $("#share-basket-modal ul label").addClass("error")
            $("#share-basket-modal .form-errors").append("<span class='error'>"+message+"</span>")
          }
          else {
            $(`[name='${String(field)}']`).addClass("error").after("<span class='error'>"+message+"</span>")
          }
        })
      }
    },
    success: function(result) {
      $form.find('[name="share-to"]').val("")
      toastr.success(result.message, "Success", {timeOut: 0, extendedTimeOut: 0})
    }
  }).done(() => {
    $wait.addClass("hidden")
    $button.removeAttr("disabled")
  })
})


// ====================================================================================================================
// Guest Basket
// ====================================================================================================================
window.setupGuestBasket = function() {
  let localBasketDataStore = new LocalBasketStore()

  if (localBasketDataStore.totalCount() > 0) {
    const $form = $("<form/>")
    $form.append($("<input>", {type: "hidden", name: "basket", value: localBasketDataStore.toJson()}))

    $.ajax({
      url: "/" + $("body").data("market-route-prefix" ) + "/basket/guest",
      data: $form.serialize(),
      async: true,
      method: "POST",
      dataType: "html",
      success: function(data) {
        const $oldBody = $("#basket-body")
        const $newBody = $(data);
        $oldBody.replaceWith($newBody)
        window.setupBasket()
        // The grids are normally initialized after DOMContentLoaded, but in this case they grids aren't available
        // until now.
        window.initMasonryGrids()
      }
    })
  }
}


// ====================================================================================================================
// License Acceptance / Download
// ====================================================================================================================
window.acceptLicense = function() {
  document.cookie = "newsroom.accepted_license=1; path=/"
  $("#licenseModal").modal('hide')
  const downloadUrl = $("#licenseModal").data("downloadUrl")

  if (downloadUrl.match("basket\/guest\/download")) {
    window.localBasketDataStore.post(downloadUrl)
  } else {
    window.location.href = decodeURIComponent(downloadUrl)
  }
}


// ====================================================================================================================
// .back-to-top-corner Handler
// ====================================================================================================================
function enableBackToTopScrollDetection() {
  $(document).one("scroll", () => $(".back-to-top-corner").addClass("is-enabled"))
}


// ====================================================================================================================
//
// ====================================================================================================================

$(() => {
  window.initMasonryGrids()

  const destination = $("head").data("post-login-download");
  if(destination !== "#" && destination !== undefined && !/\/login$/.test(window.location.href) && $("body.authenticated").length > 0) {
    window.location = destination;
  }

  $(".login-form").on("submit",() => {
    // Inject the guest basket data into the login form
    const hiddenBasketInput = $("<input type=\"hidden\" name=\"basketDocument\">")
    hiddenBasketInput.val(window.localBasketDataStore.toJson)
    $(".login-form").append(hiddenBasketInput)
  })

  $(document).on("click", ".download-overlay__link", function(e) {
    $(e.currentTarget).parents(".download-overlay").collapse('hide')
  })

  $('#quick-links').on("click",function(e){
    e.preventDefault()
    $(".site-footer-container--links").toggleClass("is-expanded")
  })

  $("ul#sort-options a[href!='#']").on("click", function (e) {
    e.preventDefault()
    e.stopPropagation()

    paginatedSearch({sortOrder: $(e.target).data("sort-order")})
  })

  $(".release-grid-indicator").on("click", (e) => {
    $(e.target)
      .parents('.release-list')
      .removeClass('release-list')
      .addClass('release-grid')

    createCookie('homepage-release-format', 'release-grid', 9999)
  })

  $(".release-list-indicator").on("click", (e) => {
    $(e.target)
      .parents('.release-grid')
      .removeClass('release-grid')
      .addClass('release-list')

    createCookie('homepage-release-format', 'release-list', 9999)
  })

  $("[data-password-toggle-ref]").on("click", function() {
    const inputElement = $($(this).data("password-toggle-ref"))
    if(inputElement.attr('type') === "text") {
      inputElement.attr('type', 'password')
    } else {
      inputElement.attr('type', 'text')
    }

    inputElement.trigger("focus")
  })


  // ===================================================================================================================
  // Menu
  // ===================================================================================================================
  $('.slimmenu').slimmenu({
    menuToggleButton: $('.menu-toggle-button')
  })


  // ===================================================================================================================
  // HOMEPAGE GRID/LIST TOGGLE
  // ===================================================================================================================

  $('.homepage_latest-news--list-indicator').on('click', function() {
    $('.homepage_latest-news--list-indicator').addClass('active')
    $('.homepage_latest-news--grid-indicator').removeClass('active')
    $('.homepage_latest-news--grid').toggleClass('homepage_latest-news--grid homepage_latest-news--list')
    createCookie('homepage-release-format', 'homepage_latest-news--list', 9999)
  })

  $('.homepage_latest-news--grid-indicator').on('click',function() {
    $('.homepage_latest-news--grid-indicator').addClass('active')
    $('.homepage_latest-news--list-indicator').removeClass('active')
    $('.homepage_latest-news--list').toggleClass('homepage_latest-news--list homepage_latest-news--grid')
    createCookie('homepage-release-format', 'homepage_latest-news--grid', 9999)
  })


  // ===================================================================================================================
  // Download Overlays
  // ===================================================================================================================
  $('.grid__item__download-overlay__button').on('click', function() {
    $(this).parents(".grid__item ").find('.grid__item__download-overlay').addClass('active')
  })

  $('.download-overlay__cancel a').on('click',function() {
    $(this).parents(".grid__item ").find('.grid__item__download-overlay').removeClass('active')
  })


  // ===================================================================================================================
  // License Agreement Handling
  // ===================================================================================================================
  $(document).on("click", "a[data-license-check-download]", function(e) {
    e.preventDefault()
    const url = $(this).attr("href")!
    const isGuestBasket = $(this).hasClass("license-check-post-download")

    if(getCookie("newsroom.accepted_license") === "1") {
      if(isGuestBasket && window.localBasketDataStore) {
        window.localBasketDataStore.post(url)
      } else {
        window.location.href = url
      }
    } else {
      showLicenseAgreementModal(url)
    }
  })

  // ===================================================================================================================
  // Back to Top
  // ===================================================================================================================
  $(".back-to-top-corner").on("click", function() {
    window.scrollTo(0,0)
    $(this).removeClass("is-enabled")
    window.setTimeout(enableBackToTopScrollDetection, 100)
  })

  enableBackToTopScrollDetection()
})

// =====================================================================================================================
// Custom Dropdown Behavior
// =====================================================================================================================
$(document).on('hide.bs.dropdown', function (e) {
  if (!e.clickEvent) {
    // There is no `clickEvent` property in the `e` object when the `button` (or any other trigger) is clicked.
    // What we usually want to happen in such situations is to hide the dropdown so we let it hide.
    return true
  }

  const keepOpen = e.clickEvent.target.classList.contains("dropdown-menu--keep-open") ||
    e.target.classList.contains("dropdown-menu--keep-open") ||
    $(e.clickEvent.target).parents('.dropdown-menu--keep-open').length > 0

  return !keepOpen
})

// =====================================================================================================================
// activateRelatedMediaTab
// =====================================================================================================================
window.activateRelatedMediaTab = function(contentType: string) {
  $('.sitewide_tab-bar-nav-item[data-content-type="' + contentType + '"] a').trigger("click")
  window.scrollTo(0, 0)
}

// =====================================================================================================================
// Tooltips
// =====================================================================================================================

$(function () {
  $('[data-toggle-tooltip]')
    .tooltip()
    .on("inserted.bs.tooltip", (e) => {
      document
        .getElementById(e.target.getAttribute("aria-describedby")!)
        .classList
        .add("content-tooltip")
    })
})

